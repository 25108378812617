<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Create new Service Request
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ invalid }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="calendar-booking-form"
            @submit.prevent="submitForm"
          >
            <div
              class="px-2"
            >
              <!-- Calendar -->
              <validation-provider
                #default="{ errors }"
                name="Service Form"
                rules="required"
              >

                <b-form-group
                  label="Select an existing Service Form"
                  label-for="activityType"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="serviceForm"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="serviceForms"
                    label="stringID"
                    placeholder="Select Service Form"
                    :reduce="calendar => calendar._id"
                    input-id="serviceForm"
                    :clearable="true"
                    @input="setFormDetail"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <div v-if="previewFormDetail">
                <h5 class="font-weight-bolder">
                  Applicant Name
                </h5>
                <p>{{ formDetail.customerName ? formDetail.customerName : '-' }}</p>
                <h5 class="font-weight-bolder">
                  Mobile Number
                </h5>
                <p>{{ formDetail.customerContact ? `${formDetail.customerContact}` : '-' }}</p>
                <h5 class="font-weight-bolder">
                  Agent Name
                </h5>
                <p>{{ formDetail.agentName ? formDetail.agentName : '-' }}</p>
                <h5 class="font-weight-bolder">
                  Agent’s Mobile Number
                </h5>
                <p>{{ formDetail.agentContact ? `${formDetail.agentContact}` : '-' }}</p>
                <h5 class="font-weight-bolder">
                  Duty Officer
                </h5>
                <p>{{ formDetail.dutyOfficer ? (formDetail.dutyOfficer.name ? formDetail.dutyOfficer.name : '-') : '-' }}</p>
              </div>
            </div>
            <hr class="dividerHR">
            <div
              class="px-2"
            >
              <!-- Form Actions -->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                type="submit"
                class="w-100"
                :disabled="!serviceForm || invalid"
              >
                Create a Service Request
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="link"
                class="w-100 mt-2"
                :to="{ name: 'operation-service-forms-create' }"
              >
                Create a new Service Form
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/named
import {
  required,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,

    vSelect,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      serviceForms: [],
      serviceForm: '',
      formDetail: {},
      previewFormDetail: false,
      required,
    }
  },
  beforeMount() {
    this.$http.get('operation/calendar/respond-with/service-forms')
      .then(response => {
        this.serviceForms = response.data.serviceForms ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    setFormDetail() {
      const form = this.serviceForms.find(o => o._id === this.serviceForm)
      if (form) {
        this.formDetail = form
        this.previewFormDetail = true
      } else {
        this.formDetail = {}
        this.previewFormDetail = false
      }
    },

    submitForm() {
      this.$router.push({ name: 'operation-service-requests-create', query: { form: this.serviceForm } })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
